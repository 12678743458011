export const isNonEmptyArray = <T>(arr: unknown): arr is T[] => {
  if (!Array.isArray(arr)) {
    return false;
  }

  return arr.length > 0;
};

export const isString = (str: unknown): str is string => {
  return typeof str === 'string';
};

export const isNumber = (val: unknown): val is number => {
  return typeof val === 'number' && !Number.isNaN(Number(val));
};

export const isNonEmptyString = (str: unknown): str is string => {
  if (!isString(str)) {
    return false;
  }

  return str.length > 0;
};

export const isValidNumber = (data: unknown): data is number => {
  return !Number.isNaN(Number(data));
};

export const isObject = <T extends Record<string, unknown>>(data: unknown): data is T => {
  if (typeof data !== 'object') {
    return false;
  }

  // null will return typeof 'object'... (facepalm)
  if (!data) {
    return false;
  }

  // arrays are also 'objects'...
  if (Array.isArray(data)) {
    return false;
  }

  // we know its an object if we made it this far
  return true;
};

export const isNonEmptyObject = <T extends Record<string, unknown>>(data: unknown): data is T => {
  if (!isObject(data)) {
    return false;
  }

  return Object.keys(data).length > 0;
};

export const arrayStringsAreUnique = (arr: string[]) => {
  const unique = [...new Set(arr)];

  return unique.length === arr.length;
};

export const isLocaleString = (locale: string) => {
  if (!isNonEmptyString(locale)) {
    return false;
  }

  const [language, country] = locale.split('-');
  if (!isNonEmptyString(language) || !isNonEmptyString(country)) {
    return false;
  }

  return true;
};

export const isNonEmptyJson = (val: unknown) => {
  return isNonEmptyObject(val) || isNonEmptyArray(val);
};

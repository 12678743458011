import React from 'react';

import Image from 'next/image';

const ImageComponent = (props) => {
  const { attributes } = props;
  const {
    className,
    height,
    layout,
    mediaAlt,
    mediaURL,
    nonResponsive,
    preload,
    width,
  } = attributes;

  if (nonResponsive) {
    return (
      <img
        className={className}
        loading="lazy"
        src={mediaURL}
      />
    );
  } else {
    let layoutToUse = layout;

    if (!layout) {
      layoutToUse = width ? 'responsive' : 'fill';
    }

    return (
      <Image
        alt={mediaAlt}
        className={className}
        height={height}
        layout={layoutToUse}
        priority={preload}
        src={mediaURL}
        width={width}
      />
    );
  }
};

export default ImageComponent;

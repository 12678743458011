import React from 'react';

import loadable from '@loadable/component';

import { COLORS } from '../../tailwind/config';

const Icon = ({ attributes }) => {
  const {
    className, color, name, style, type,
  } = attributes;

  const SVG = loadable(() => import(`../../assets/svg/${type}/${style}/${name}`));

  return <SVG color={COLORS[color] || '#000'} iconClass={className} />;
};

export default Icon;

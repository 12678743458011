import {
  isLocaleString,
  isNonEmptyString
} from '@rippling/utils/validationUtils';
import { NextRequest } from 'next/server';

import {
  BLOG_LOCALES, LOCALES, LOCALES_LIST
} from '../locales';

const NON_LOCALIZED_PAGE_PATHS = ['blog'];

const NON_LOCALIZED_PATHS_REGEXPS = NON_LOCALIZED_PAGE_PATHS.map((rootPath: string) => {
  const pattern = `^/${rootPath}$|^/${rootPath}/(.*)`;

  return new RegExp(pattern);
});

export const localeIsAllowedOnSite = (locale: string) => {
  return LOCALES_LIST.includes(locale);
};

export const getLocaleSubFields = (locale: string) => {
  const [language, country] = locale.split('-');

  return {
    country,
    language,
  };
};

const makeLocaleFromSubFields = ({
  country,
  language,
}: {
  country: string;
  language: string;
}) => {
  return `${language.toLowerCase()}-${country.toUpperCase()}`;
};

/**
 * @param headerValue - 'en-ca,en;q=0.8,en-us;q=0.6,de-de;q=0.4,de;q=0.2'
 */
export const getLocaleFromAcceptLanguageHeaderOrNull = (headerValue: string) => {
  if (!isNonEmptyString(headerValue)) {
    return null;
  }

  const lowercaseLangVal = headerValue.toLowerCase();

  // en-ca (first entry should be strongest)
  const lowercaseLocaleMatch =
    lowercaseLangVal.match(/[a-z]{2}-[a-z]{2}.*?/)?.[0] || null;

  if (!isLocaleString(lowercaseLocaleMatch)) {
    return null;
  }

  // { language, country }
  const localeSubFields = getLocaleSubFields(lowercaseLocaleMatch);

  // en-CA
  return makeLocaleFromSubFields(localeSubFields);
};

/**
 * @param headerValue - 'en-ca,en;q=0.8,en-us;q=0.6,de-de;q=0.4,de;q=0.2'
 */
export const getLangFromAcceptLanguageHeaderOrNull = (headerValue: string) => {
  if (!isNonEmptyString(headerValue)) {
    return null;
  }

  // if no locale was found, attempts to get it from a language value (en)
  const lowercaseLangVal = headerValue.toLowerCase();

  // en (first entry should be strongest)
  return lowercaseLangVal.match(/[a-z]{2}.*?/)?.[0] || null;
};

export const getLocaleFromRequestOrNull = (req: NextRequest): null | string => {
  const acceptLanguageHeader = req.headers.get('accept-language');
  const headerPreferredLocale = getLocaleFromAcceptLanguageHeaderOrNull(acceptLanguageHeader);

  // if the preferred header locale is available on the site, lets use that.
  if (
    isLocaleString(headerPreferredLocale) &&
    localeIsAllowedOnSite(headerPreferredLocale)
  ) {
    return headerPreferredLocale;
  }

  // otherwise, we try to peice our own together
  const headerLanguage = getLangFromAcceptLanguageHeaderOrNull(acceptLanguageHeader);
  const reqCountry = req.geo.country;

  // if either are missing, its not worth guessing wrong...
  if (!isNonEmptyString(headerLanguage) || !isNonEmptyString(reqCountry)) {
    return null;
  }

  // return an accurate guess if we have both.
  return makeLocaleFromSubFields({
    country: reqCountry,
    language: headerLanguage,
  });
};

export const isLocalizedBlog = (locale) => {
  return BLOG_LOCALES.includes(locale);
};

export const isBlogNotFound = (pathname: string, requestedLocale: string) => {
  const pattern = /^\/blog$|^\/blog\/(.*)/;

  if (pattern.test(pathname) && !BLOG_LOCALES.includes(requestedLocale)) {
    return true;
  }

  return false;
};

const testEnUSPagePath = (pathname: string) => {
  return NON_LOCALIZED_PATHS_REGEXPS.some((reg) => reg.test(pathname));
};

export const isUSPathOnly = (pathname: string) => {
  // Redirect all non en-US traffic to en-US
  if (testEnUSPagePath(pathname)) {
    return true;
  }

  return false;
};

export const shouldForceEnUsLocale = (
  pathname: string,
  requestedLocale: string
) => {
  if (isUSPathOnly(pathname) && !BLOG_LOCALES.includes(requestedLocale)) {
    return true;
  }

  return false;
};

export const getAssumedUserLocaleOrEnUs = (req: NextRequest): string => {
  const reqLocal = req.nextUrl.locale;
  const cookieLocale = req.cookies.get('NEXT_LOCALE')?.value;

  // Only use locale logic if entering on en-US site
  if (reqLocal !== LOCALES.EN_US) {
    return reqLocal;
  }

  // no need to do extra work if its cookied and valid...
  if (isLocaleString(cookieLocale) && localeIsAllowedOnSite(cookieLocale)) {
    return cookieLocale;
  }

  // attempt to get the locale from the accept language header
  const assumedLocale = getLocaleFromRequestOrNull(req);
  if (isLocaleString(assumedLocale) && localeIsAllowedOnSite(assumedLocale)) {
    return assumedLocale;
  }

  // if this is the first time a user visits and we can predict their locale,
  // it would be predictable if we assumed the first link locale as thier setting...
  if (isLocaleString(reqLocal) && localeIsAllowedOnSite(reqLocal)) {
    return reqLocal;
  }

  // if all else fails, lets assume en-US
  return LOCALES.EN_US;
};

export const shouldRedirectUserRequest = (
  requestedLocale: string,
  userLocale: null | string,
  pathname: string
) => {
  if (!userLocale) {
    return false;
  }

  // Don't do an i18n redirect for US only paths
  if (isUSPathOnly(pathname)) {
    return false;
  }

  // we only want to redirect external locale traffic
  // en-FR users should be able to see en-GB if they click an en-GB link
  // en-FR users should be redirected if they click on a en-US link
  const requestedPageIsEnUS = requestedLocale === LOCALES.EN_US;

  return (
    requestedPageIsEnUS &&
    requestedLocale !== userLocale &&
    localeIsAllowedOnSite(userLocale)
  );
};

export const phrasePrefixes = {
  customer_spotlights: 'customers/',
  events: 'events/',
  page: '',
  partials: '@',
  post: 'blog/',
  recipes: 'recipes/',
  reusable: '*',
  webinars: 'webinars/',
};

export const makePhraseJobFileName = (type, slug) => {
  const phrasePrefix = phrasePrefixes[type] || '';

  return `${phrasePrefix}${slug}`;
};

export const replaceUnicodes = (construct) => {
  if (!construct) {
    return '';
  }

  // @TODO Find a function to replace all unicodes to utf-8
  return construct
    .replaceAll('&amp;', '&')
    .replaceAll(String.raw`\u0026`, '&')
    .replaceAll(String.raw`\u0026amp;`, '&')
    .replaceAll(String.raw`\u00d7`, '×')
    .replaceAll(String.raw`\u2018`, '‘')
    .replaceAll(String.raw`\u2019`, '’')
    .replaceAll(String.raw`\u201c`, '“')
    .replaceAll(String.raw`\u201d`, '”')
    .replaceAll(String.raw`\u2014`, '—')
    .replaceAll(String.raw`\u0022`, '"')
    .replaceAll(String.raw`\u003e`, '>')
    .replaceAll(String.raw`\u003ca`, '<')
    .replaceAll(String.raw`\/`, '/');
};

import React from 'react';

import { reformatAnchorText } from '../helpers';

const Paragraph = (props) => {
  const { attributes } = props;
  const {
    className, data, innerContent,
  } = attributes;

  const anchorRegex = /<a\s+[^>]+>((?:.(?!<\/a>))*.)<\/a>/g;

  if (!innerContent) {
    return null;
  }

  if (anchorRegex.test(innerContent)) {
    // Does the content contain an anchor tag?
    return (
      <p className={className} {...data}>
        {reformatAnchorText(innerContent)}
      </p>
    );
  }

  return (
    <p
      dangerouslySetInnerHTML={{ __html: innerContent }}
      className={className}
      {...data}
    />
  );
};

export default Paragraph;

import React from 'react';

import classnames from 'classnames';
import parse from 'html-react-parser';

import LinkHelper from '../templates/partials/LinkHelper';

function encodeHTML(s) {
  return typeof s === 'string'
    ? s.replaceAll('&', '&amp;').replaceAll('<', '&lt;').replaceAll('"', '&quot;')
    : s;
}

const parseChildren = (data, handler, index) => {
  // Resuable Block
  if (Array.isArray(data) && data.length === 1) {
    data = data[0];
  }

  let children = data.children || data.props.children;

  const result = {};

  // Not outermost result flow
  const innerResult = data.props ? handler(data, children, index) : null;

  children = innerResult?.props?.children || children;

  if (Array.isArray(children)) {
    children = children.map((child, index) => {
      return parseChildren(child, handler, index);
    });
  }

  if (innerResult) {
    return React.cloneElement(innerResult.el, {
      ...innerResult.props,
      children: children,
      key: index,
    });
  }

  result.children = children;

  return result;
};

function recursiveMap(children, fn) {
  return React.Children.map(children, (child, i) => {
    if (!React.isValidElement(child)) {
      return child;
    }

    let item = fn(child, i);

    if (item.props.children) {
      item = React.cloneElement(item, { children: recursiveMap(item.props.children, fn) });
    }

    return item;
  });
}

const getClassNameFromAttributes = (attributes) => {
  let classname = '';

  const colors = [
    {
      color: '#fff',
      name: 'White',
      slug: 'white',
    },
    {
      color: 'rgba(255, 255, 255, 0.7)',
      name: 'Gray',
      slug: 'gray',
    },
    {
      color: 'rgba(0, 0, 0, 0.4)',
      name: 'Dark Gray',
      slug: 'dark-gray',
    },
    {
      color: '#FDB71C',
      name: 'Yellow',
      slug: 'yellow',
    },
    {
      color: '#f6f3f0',
      name: 'Eggshell',
      slug: 'eggshell',
    },
    {
      color: '#f0e8df',
      name: 'Dark Eggshell',
      slug: 'dark-eggshell',
    },
    {
      color: '#2374E1',
      name: 'Blue',
      slug: 'blue',
    },
    {
      color: '#502b3a',
      name: 'Plum',
      slug: 'plum',
    },
    {
      color: '#301923',
      name: 'Dark Plum',
      slug: 'dark-plum',
    },
    {
      color: '#38202A',
      name: 'Dark Unsaturated Plum',
      slug: 'dark-unsaturated-plum',
    },
  ];

  const measures = {
    mb: true,
    mbd: true,
    mw: true,
    mwd: true,
    pb: true,
    pbd: true,
    pl: true,
    pld: true,
    pr: true,
    prd: true,
    pt: true,
    ptd: true,
    ta: true,
    w: true,
    wd: true,
    wf: true,
    wfd: true,
  };

  for (const [key, attr] of Object.entries(attributes)) {
    if (key === 'color') {
      const colorObject = colors.find((color) => color.color === attr || color.slug === attr);

      if (colorObject) {
        classname = classnames(classname, { [`has-${colorObject.slug}-background-color`]: colorObject.slug });
      }
    } else if (key === 'textColor') {
      const colorObject = colors.find((color) => color.color === attr || color.slug === attr);

      if (colorObject) {
        classname = classnames(classname, { [`has-${colorObject.slug}-color`]: colorObject.slug });
      }
    } else if (measures[key]) {
      const newAttr = attr === 0 ? '0' : attr;
      classname = classnames(classname, { [`-${key}-${newAttr}`]: newAttr });
    }
  }

  return classname;
};

const getIncrementValue = ({
  activeIndex, increment, numOfItems,
}) => {
  const curInc = activeIndex + increment;

  if (curInc === numOfItems) {
    return 0;
  }

  if (curInc === -1) {
    return numOfItems - 1;
  }

  return curInc;
};

const optionize = (optionsStr) => {
  const optionsObj = {};

  if (optionsStr) {
    for (const dataAttribute of optionsStr
      .trim()
      .split(/\r?\n/g)) {
      const [name, value] = dataAttribute.split('=>');

      optionsObj[name] = value;
    }
  }

  return optionsObj;
};

function debounce(fn, ms) {
  let timer;

  return () => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      timer = null;
      Reflect.apply(fn, this, arguments);
    }, ms);
  };
}

function shuffle(array) {
  let currentIndex = array.length;
  let temporaryValue;
  let randomIndex;

  // While there remain elements to shuffle...
  while (currentIndex !== 0) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
}

function getUrlParam(name) {
  const results = new RegExp(`[?&]${name}=([^&#]*)`).exec(window.location.href);

  return results == null ? null : decodeURI(results[1]) || 0;
}

function escapeRegex(string) {
  return string.replaceAll(/[$()*+./?[\\\]^{|}-]/g, String.raw`\$&`);
}

const capitalize = (s) => {
  if (typeof s !== 'string') {
    return '';
  }

  return s.charAt(0).toUpperCase() + s.slice(1);
};

const stripHtml = (html) => {
  return html.replaceAll(/<[^>]*>/g, '');
};

const traverseComponents = (components) => {
  if (!Array.isArray(components)) {
    components = [components];
  }

  return components.map((component, i) => {
    if (typeof component === 'string') {
      return component;
    }

    let newChildren;
    const { props, type } = component;
    let newProps = { ...props };

    if (Array.isArray(props.children)) {
      newChildren = props.children.map((child) => traverseComponents(child));
      newProps = { ...newProps };
    }

    const children = newChildren || props.children;

    if (type === 'a') {
      return (
        <LinkHelper {...newProps} key={i}>
          {children}
        </LinkHelper>
      );
    }

    return React.cloneElement(component, newProps, children);
  });
};

const reformatAnchorText = (innerContent) => {
  const parsedContent = parse(innerContent);

  return traverseComponents(parsedContent);
};

export {
  capitalize,
  debounce,
  encodeHTML,
  escapeRegex,
  getClassNameFromAttributes,
  getIncrementValue,
  getUrlParam,
  optionize,
  parseChildren,
  recursiveMap,
  reformatAnchorText,
  shuffle,
  stripHtml
};
